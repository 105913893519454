import React from 'react'
import { Heading, Flex, Box, Container, Text, List, ListItem, UnorderedList, Link } from '@chakra-ui/react'
import HookForm from '../components/form-wth-stats'
import Header from '../components/header'

export default function Home() {
        const Project = 'NT1002'
        const BASECAMP_PROJECT_ID = '20081310'

        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                {Project} Casting for Car Commercial
                        </Heading>
                        <Container mb={8} padding={1} width="clamp(340px, 60%, 800px)">
                                <Box mb={8}>
                                        <Heading
                                                as="h3"
                                                fontSize="l"
                                                m="2"
                                                align="left"
                                                textTransform="uppercase"
                                                mt={8}
                                        >
                                                Role Details :
                                        </Heading>
                                        <Text mb={4} ml={2}>
                                                Age late 30s/early 40s, height 6ft/183cm max. Athletic physical
                                                condition, but not too muscular. He looks confident and is used to
                                                living by his own rules He’s relatable to our life achiever audience.
                                                <br />
                                                He should have the right attitude and look focused but he shouldn’t be
                                                too serious or imposing. He has a beautiful smile to transmit to the
                                                viewer the joy of driving a GT Speed, and an engaging but not too
                                                polished look, he’s not a supermodel. <br />
                                                We would like to see actors who have different lengths of stubble and
                                                hair.
                                                <br />
                                                <strong mb={2}>
                                                        Please confirm if you are willing to have your hair cut (but not
                                                        shaven) on your self-tape.
                                                </strong>
                                        </Text>
                                        <UnorderedList spacing={1} ml={8}>
                                                <ListItem>
                                                        <strong>Playing Age:</strong> 35–45 years
                                                </ListItem>
                                                <ListItem>
                                                        <strong>Height:</strong> 61 — 183 cm
                                                </ListItem>
                                                <ListItem>
                                                        <strong>Gender:</strong> Male, Transgender - male
                                                </ListItem>
                                                <ListItem>
                                                        <strong>Appearance:</strong> any appearance
                                                </ListItem>
                                        </UnorderedList>
                                </Box>
                                <Box mb={8}>
                                        <Heading
                                                as="h3"
                                                fontSize="l"
                                                m="2"
                                                align="left"
                                                textTransform="uppercase"
                                                mt={8}
                                        >
                                                Production Dates :
                                        </Heading>
                                        <UnorderedList spacing={1} ml={8}>
                                                <ListItem>
                                                        <strong>Recall:</strong> Thursday 4th or 5th February
                                                </ListItem>
                                                <ListItem>
                                                        <strong>Fitting:</strong> likely on 10th or 11th February
                                                </ListItem>
                                                <ListItem>
                                                        <strong>Covid Test:</strong> morning of 15th or 16th February
                                                        (only for 10 minutes compulsory and unpaid)
                                                </ListItem>
                                                <ListItem>
                                                        <strong>Shoot:</strong> 17-19th February
                                                </ListItem>
                                        </UnorderedList>
                                </Box>
                                <Box>
                                        <Heading
                                                as="h3"
                                                fontSize="l"
                                                m="2"
                                                align="left"
                                                textTransform="uppercase"
                                                mt={8}
                                        >
                                                Production Location : Manchester
                                        </Heading>

                                        <Box mb={8}>
                                                <Heading
                                                        as="h3"
                                                        fontSize="l"
                                                        m="2"
                                                        align="left"
                                                        textTransform="uppercase"
                                                        mt={8}
                                                >
                                                        Contract Info :
                                                </Heading>
                                                <UnorderedList spacing={1} ml={8}>
                                                        <ListItem>
                                                                <strong>Contract Type:</strong> Non-union - Commercially
                                                                Financed
                                                        </ListItem>
                                                        <ListItem>
                                                                <strong>Shoot Length:</strong> 2-3 days{' '}
                                                        </ListItem>
                                                        <ListItem>
                                                                <strong>Fees:</strong>BSF £500 per day.
                                                        </ListItem>
                                                        <ListItem>
                                                                <strong>Buy Out Fee:</strong> £12,000
                                                        </ListItem>
                                                        <ListItem>
                                                                <strong>Buy Out Period:</strong> 3 years global with
                                                                marketing rights (online, paid media, social and print –
                                                                no ATT)
                                                        </ListItem>
                                                </UnorderedList>
                                        </Box>
                                        <Box>
                                                <Heading
                                                        as="h3"
                                                        fontSize="l"
                                                        m="2"
                                                        align="left"
                                                        textTransform="uppercase"
                                                >
                                                        Submission Requirements:
                                                </Heading>
                                                <UnorderedList spacing={1} ml={8}>
                                                        <ListItem fontWeight="bold" textTransform="uppercase">
                                                                Self tape deadlines: 5pm - Monday 1st February.
                                                        </ListItem>
                                                        <ListItem>
                                                                Please send self tapes by wetransfer to
                                                                assistant@toppscasting.co.uk
                                                        </ListItem>
                                                        <ListItem>
                                                                Please complete, sign and scan our{'    '}
                                                                <Link
                                                                        color="red.500"
                                                                        href="ipa_artists_declaration_form_part_1_final_18_may_2018.pdf"
                                                                        download
                                                                >
                                                                        Artist's Declaration Form
                                                                </Link>
                                                        </ListItem>
                                                </UnorderedList>
                                        </Box>
                                </Box>
                        </Container>
                        <Flex as="main" width="full" align="center" flexDirection="column" justifyContent="center">
                                <HookForm project={Project} basecampprojectid={20081310} />
                        </Flex>
                </>
        )
}
